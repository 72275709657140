import React from 'react';
import {useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {BrComponent} from '@bloomreach/react-sdk';

const PoliciesContainer = styled.div`
    margin-bottom: 100px;

    a {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
    }
`;

const PolicySection = styled.div`
    padding: 20px;
`;

const PolicySectionTitle = styled.h3`
    margin-bottom: 5px;
    font-size: 16px;
    color: #4A4A4A;
    font-weight: 700;
    line-height: 24px;
    color: ##4A4A4A;
    font-family: "Source Sans Pro", sans-serif;
`;

const PolicySectionNavLink = styled(NavLink)`
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    font-weight: bold;
    color: #000000;
    font-family: "Source Sans Pro", sans-serif;
    display: block;
    text-decoration: none

    &.selected {
        color: #005DA6;
    }

    &.active {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }
`;

const LinkContainer = styled.div`
    margin-bottom:24px;
    text-align:left;
`;

const BackToPoliciesLink = styled(NavLink)`
    color: #777777;
    font-weight:300;
    font-size: 14px;
    padding-left: 10px;
    cursor: pointer;
    text-decoration: none

    &.selected {
        color: #777777;
    }

    &.active {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;}
`;

const NavLinks = styled.div`
    @media screen and (max-width: 799px) {
        display:none;
    }
`;

const Policies = () => {
    const location = useLocation();

    const linkClass = (path) => {
        return location.pathname === path ? 'selected' : '';
    }

    const showBackToPolicies = !(location.pathname === '/policies' || location.pathname == '/policies/');

    return (
        <PoliciesContainer>
            <div className="row">
                <NavLinks className="col-md-3">
                    <PolicySection>
                        <PolicySectionTitle>POLICIES</PolicySectionTitle>
                        <PolicySectionNavLink to="/policies/privacypolicy" className={linkClass('/policies/privacypolicy')}>Privacy Policy</PolicySectionNavLink>
                        <PolicySectionNavLink to="/policies/cookiespolicy" className={linkClass('/policies/cookiespolicy')}>Cookies Policy</PolicySectionNavLink>
                        <PolicySectionNavLink to="/policies/accessibility" className={linkClass('/policies/accessibility')}>Accessibility Policy</PolicySectionNavLink>
                        <PolicySectionNavLink to="/policies/eula" className={linkClass('/policies/eula')}>End User License Agreement</PolicySectionNavLink>
                    </PolicySection>
                    <PolicySection>
                        <PolicySectionTitle>TERMS &amp; CONDITIONS</PolicySectionTitle>
                        <PolicySectionNavLink to="/policies/termsandconditionofsale" className={linkClass('/policies/termsandconditionofsale')}>Terms &amp; Conditions - Customer</PolicySectionNavLink>
                        <PolicySectionNavLink to="/policies/termsandconditionofpurchase" className={linkClass('/policies/termsandconditionofpurchase')}>Terms &amp; Conditions - Supplier</PolicySectionNavLink>
                        <PolicySectionNavLink to="/policies/termsofuse" className={linkClass('/policies/termsofuse')}>Terms of Use</PolicySectionNavLink>
                    </PolicySection>
                </NavLinks>
                <div className="col-xs-12 col-md-6">
                    {showBackToPolicies &&
                        <LinkContainer>
                            <span><img src="/images/icn_left-chevron.png" /><span><BackToPoliciesLink to="/policies">Back to Policies</BackToPoliciesLink></span></span>
                        </LinkContainer>}
                    <BrComponent path={'main'} />
                </div>
            </div>
        </PoliciesContainer>
    );
}

export default Policies;
